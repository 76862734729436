import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  NavAbout,
  CDefinition,
  CMedia,
  CSpChangeImg,
  CTileImg,
  CBtnList,
  CFixedImg,
  CSectTitle,
} from "../../../components/_index";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            ja: "SDGsの取組",
          },
          img: {
            src: "/assets/images/common/kv.png",
          },
          imgSp: {
            src: "/assets/images/common/kv__sp.png",
          },
        }}
      />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "三菱地所ホテルズ＆リゾーツについて",
              path: "/about/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <div className="l_sect">
        <LWrap exClass="u_mbLarge">
          <p className="c_sectLead">
            ロイヤルパークホテルズは、SDGs実現に対する責任の一端を担い、課題解決とともに、
            <br className="u_pc" />
            社会から、利用者から、取引先からの信頼を得るために、さまざまな取組を行っております。
          </p>
        </LWrap>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: "ロイヤルパークホテルズの取組",
                en: "ROYAL PARK HOTELS INITIATIVES",
              }}
            />
            <CMedia
              data={[
                {
                  imgs: [
                    {
                      img: {
                        src: "/assets/images/about/sdgs/img_initiatives.png",
                        alt: "生分解性ストローの導入",
                      },
                    },
                  ],
                  title: "生分解性ストローの導入",
                  text: (
                    <>
                      <p>
                        年間約28万本使用しているプラスチック製ストロー（ストレート）を、自然環境に負担が少なく、生分解をする植物由来の生分解性ストロー（※）への変更を実施しております。
                      </p>
                      <ul className="c_noteList">
                        <li>
                          生分解性ストロー：・導入製品：JBPA（日本バイオマスプラスチック協会）グリーンプラマーク取得の生分解性ストロー（トウモロコシなどのでんぷんから得られるポリ乳酸を原料とする植物由来の自然循環型樹脂を原料）
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  imgs: [
                    {
                      img: {
                        src: "/assets/images/about/sdgs/img_initiatives02.png",
                        alt: "客室アメニティのバイオマス化",
                      },
                    },
                  ],
                  title: "客室アメニティのバイオマス化",
                  text: (
                    <>
                      <p>
                        客室アメニティ（※1）はバイオマス製品シントワールド「エコアメニティシリーズ」を使用しております。
                        <br />
                        本シリーズは、籾殻を配合した原料や再生プラスチックを使用しており、包材も紙製のため環境に配慮した製品で、ヘアブラシ・シャワーキャップ・歯ブラシはバイオマスマーク40
                        （※2）を取得しております。
                      </p>
                      <ul className="c_numberNoteList">
                        <li>
                          歯ブラシ・ヘアブラシ・カミソリ・シャワーキャップ・ボディタオル・コットン
                        </li>
                        <li>
                          生物資源（バイオマス）の割合が「10％以上（乾燥重量）」で、品質および安全性が、一定の基準を満たしている商品に表示できるマーク。
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  imgs: [
                    {
                      img: {
                        src: "/assets/images/about/sdgs/img_initiatives03.png",
                        alt: "再生ミネラルウォーターの導入",
                      },
                    },
                  ],
                  title: "再生ミネラルウォーターの導入",
                  text: (
                    <>
                      <p>
                        ごみの削減やリサイクル促進を目指し、100％リサイクルの再生ペットボトルを使用したミネラルウォーターを導入（※）しております。
                        <br />
                        再生ペットボトルは、一般回収されたペットボトルを再生可能なものにリサイクルしたプラスチックを利用しており、ペットボトルの再利用循環を促進する環境にも配慮されたものです。
                      </p>
                      <ul className="c_noteList">
                        <li>
                          ミネラルウォーターを置かない取組を実施しているホテルもございます。
                        </li>
                      </ul>
                    </>
                  ),
                },
                {
                  imgs: [
                    {
                      img: {
                        src: "/assets/images/about/sdgs/img_initiatives04.png",
                        alt: "フェアトレード認証 飲食材の導入",
                      },
                    },
                  ],
                  title: "フェアトレード認証 飲食材の導入",
                  text: (
                    <>
                      <p>
                        開発途上国の原料や製品を適正な価格で継続的に購入し、開発途上国の生産者や労働者の生活改善と自立を目指すフェアトレードの取組にホテルチェーンとして賛同し、認証ワイン、国際フェアトレード認証コーヒーを導入しております。
                      </p>
                    </>
                  ),
                },
                {
                  imgs: [
                    {
                      img: {
                        src: "/assets/images/about/sdgs/img_initiatives05.png",
                        alt: "Fry to Fly Projectに参画",
                      },
                    },
                  ],
                  title: "Fry to Fly Projectに参画",
                  text: (
                    <>
                      <p>
                        国内資源循環による脱炭素社会実現に向けたプロジェクト「Fry
                        to Fly Project」に参加しています。
                        <br />
                        Fry to Fly
                        Projectは、個人や自治体、企業がSAF（持続可能な航空燃料）の原料となる、家庭や飲食店など身近なところで発生する廃食用油の提供を通じて、日本国内における資源循環の促進に直接参加することのできる場を提供するものです。
                      </p>
                      <CBtnList
                        exClass="u_mbLarge"
                        data={[
                          {
                            label: "Fry to Fly Project",
                            link: {
                              href: "https://www.jgc.com/jp/esg-hsse/initiative/fry-to-fly/",
                              blank: true,
                            },
                            color: "bgBlack",
                            icon: "blank",
                          },
                        ]}
                      />
                    </>
                  ),
                },
                {
                  imgs: [
                    {
                      img: {
                        src: "/assets/images/about/sdgs/img_initiatives06.png",
                        alt: "京町家を活用した宿泊施設「京の温所」の運営",
                      },
                    },
                  ],
                  title: "京町家を活用した宿泊施設「京の温所」の運営",
                  text: (
                    <>
                      <p>
                        京町家を活用した宿泊施設「京の温所」の運営業務を受託しています。
                        <br />
                        「京の温所」は、株式会社ワコールが展開する京町家の保全活用事業です。京都市の歴史的な街並みを象徴する京町家が老朽化や引き継ぎ手がいないなどの理由で次々と取り壊される現状を危惧し、京町家を次の世代に引き継ぎ未来に残すための事業として
                        2018年4月にスタートしました。
                      </p>
                      <CBtnList
                        exClass="u_mbLarge"
                        data={[
                          {
                            label: "京の温所",
                            link: {
                              href: "https://www.kyo-ondokoro.kyoto/",
                              blank: true,
                            },
                            color: "bgBlack",
                            icon: "blank",
                          },
                        ]}
                      />
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                ja: "ホテル独自の取組",
                en: "UNIQUE HOTEL INITIATIVES",
              }}
            />

            {/* 日本橋 */}
            <section className="u_mbExLarge">
              <h3 className="c_headingLv3 u_tac u_mbLarge">
                ロイヤルパークホテル（東京・日本橋）
              </h3>
              <CMedia
                data={[
                  {
                    imgs: [
                      {
                        img: {
                          src: "/assets/images/about/sdgs/img_unique.png",
                          alt: "",
                        },
                      },
                    ],
                    title: "地域活動の貢献に向けた持続可能な取組",
                    text: (
                      <>
                        <p>
                          近隣の小学生をホテルにご招待し、ホテルで行っているサステナブルな活動を紹介。子供たちが楽しみながらSDGsの理解を深めることができるイベントを開催しています。
                          <br />
                          また、ホテルスタッフが周辺地域の環境美化活動へ参加したり、ホテル周辺の清掃活動を積極的に実施。地域の方々との繋がりを深めつつ、日本橋の街を住み続けられる街にするため、貢献してまいります。
                        </p>
                      </>
                    ),
                  },
                  {
                    imgs: [
                      {
                        img: {
                          src: "/assets/images/about/sdgs/img_unique_02.png",
                          alt: "",
                        },
                      },
                    ],
                    title:
                      "ブッフェにおける食品ロス軽減のための効果的な提供量の管理",
                    text: (
                      <>
                        <p>
                          ブッフェでは適切な量をゲストへ提供することが難しく、残った食材が廃棄される場面が発生します。ブッフェ器材に小ぶりの器をいれダブルインナーにすることで、見た目も損なわず大量の廃棄を未然に防ぎ無駄を減らします。
                        </p>
                      </>
                    ),
                  },
                ]}
              />
              <CBtnList
                exClass="u_mbLarge"
                data={[
                  {
                    label: (
                      <>
                        ロイヤルパークホテル
                        <br />
                        （東京・日本橋）のSDGsの取組
                      </>
                    ),
                    link: {
                      href: "https://www.rph.co.jp/information/yk6wlt7ywslp/",
                      blank: true,
                    },
                    color: "bgBlack",
                    icon: "blank",
                  },
                ]}
              />
            </section>

   
            {/* キャンバス 札幌大通公園 */}
            <section className="u_mbExLarge">
              <h3 className="c_headingLv3 u_tac u_mbLarge">
                ザ ロイヤルパーク キャンバス 札幌大通公園
              </h3>
              <CMedia
                data={[
                  {
                    imgs: [
                      {
                        img: {
                          src: "/assets/images/about/sdgs/img_unique03.png",
                          alt: "",
                        },
                      },
                    ],
                    text: (
                      <>
                        <p>
                          2021年10月1日に開業した「ザ ロイヤルパーク キャンバス
                          札幌大通公園」は、地産地消をベースに「北海道を体感する」サステナブルなライフスタイルホテルです。
                          <br />
                          国内初の高層ハイブリッド木造ホテルであり、客室のほかロビー空間等へのインテリアにも北海道産木材を活用する等、食だけでなく空間そのものからも北海道を感じられるホテルです。
                          <br />
                          JAZZの街としても有名である札幌をお楽しみいただけるよう、全ての客室にレコードプレーヤー、木製のスピーカーを設置、また、アメニティの一部（歯ブラシ、シェイバー、ヘアブラシ等）は、籾殻を原料にした環境にやさしいアイテムをご用意しております。
                        </p>
                      </>
                    ),
                  },
                ]}
              />
              <ul className="c_circleList u_mb20">
                <li>
                  HOKKAIDO WOOD BUILDING　第1号認定　
                  <Link
                    to="https://www.pref.hokkaido.lg.jp/sr/rrm/02_riyousuisin/hwb-tourokuzyokyou.html"
                    target="_blank"
                    rel="noopner noreferrer"
                  >
                    北海道庁/HOKKAIDO WOOD BUILDING登録一覧ページ
                  </Link>
                </li>
                <li>
                  WOOD DESIGN賞（※）
                  2021　ハートフルデザイン部門　優秀賞（林野庁長官賞）受賞
                </li>
              </ul>
              <ul className="c_noteList">
                <li>
                  「WOOD DESIGN賞」は、林野庁の補助事業として 2015
                  年より実施され、木の良さや価値を再発見させる製品や取組について、木のある豊かな暮らしが普及・発展し、日々の生活や社会が彩られ、木材利用が進むことを目的としています。
                </li>
              </ul>
              <CBtnList
                exClass="u_mbLarge"
                data={[
                  {
                    label: (
                      <>
                        ザ ロイヤルパーク キャンバス
                        <br />
                        札幌大通公園
                      </>
                    ),
                    link: {
                      href: "https://www.royalparkhotels.co.jp/canvas/sapporoodoripark/",
                      blank: true,
                    },
                    color: "bgBlack",
                    icon: "blank",
                  },
                ]}
              />
            </section>
          </LWrap>
        </section>
      </div>

      <section className="u_mbExLarge">
        <LWrap>
          <h2 className="c_headingLv2">
            持続可能な開発目標
            <br />
            （SDGs：Sustainable Development Goals）とは
          </h2>
          <p className="u_mb40">
            2001年に策定されたミレニアム開発目標（MDGs）の後継として，2015年9月の国連サミットで加盟国の全会一致で採択された「持続可能な開発のための2030アジェンダ」に記載された，2030年までに持続可能でよりよい世界を目指す国際目標です。17のゴール・169のターゲットから構成され，地球上の「誰一人取り残さない（leave
            no one behind）」ことを誓っています。
          </p>
          <CFixedImg
            exClass="u_bgWhite"
            width={757}
            img={{
              src: "/assets/images/about/sdgs/img_unique06.png",
              alt: "Sustainable Development Goals",
            }}
          />
        </LWrap>
      </section>
      <NavAbout />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
